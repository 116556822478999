var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"AddCompany",attrs:{"id":"AddCompany","scrollable":"","no-close-on-backdrop":"","hide-header-close":"","size":"lg"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){return [(_vm.resLoader)?_c('img',{attrs:{"width":"50","height":"50","src":"/new-loader.svg"}}):_vm._e(),_c('b-button',{attrs:{"size":"sm","variant":"outline-primary"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("back"))+" ")]),_c('b-button',{attrs:{"disabled":_vm.resLoader,"size":"sm","variant":"primary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])]}}])},[_c('h3',[_vm._v(_vm._s(_vm.$t("add_new_debtor")))]),_c('validation-observer',{ref:"companyRegisterValidation",attrs:{"name":"RegisterCompany"}},[_c('h5',[_vm._v(_vm._s(_vm.$t("company_name")))]),_c('b-form',[_c('b-card',{staticClass:"col-md-12"},[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"id":"domicile-country-group","label":_vm.$t('domicile_country') + ':',"label-for":"domicile-country"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"domicile-country","options":_vm.$store.state.app.language == 'en'
                      ? _vm.countries
                      : _vm.countries_de,"state":errors.length > 0 ? false : null,"required":"","disabled":_vm.disableInputs},model:{value:(_vm.country_id),callback:function ($$v) {_vm.country_id=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"id":"company-name-group","label":_vm.$t('company_name') + ':',"label-for":"company-name"}},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptions,"limit":10,"required":"","disabled":true,"id":"company-name","input-props":{
                  id: 'autosuggest__input',
                  class: 'form-control',
                  placeholder: _vm.$t('select_company'),
                },"get-suggestion-value":_vm.getSuggestionValue},on:{"selected":_vm.onSelected,"keyup":_vm.searchForCompaniesName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(" "+_vm._s(suggestion.item)+" ")])]}}]),model:{value:(_vm.company_name),callback:function ($$v) {_vm.company_name=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"company_name"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.$t("company_uid"))+": "),_c('b',[_vm._v(_vm._s(_vm.companies[_vm.company_name] ? _vm.companies[_vm.company_name] : _vm.company_uid))])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[(_vm.companies[_vm.company_name] != null)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.getCompanyDataFromZefix()}}},[_c('feather-icon',{attrs:{"icon":"DownloadIcon"}}),_vm._v(" "+_vm._s(_vm.$t("get_data"))+" ")],1):_vm._e()],1)],1)],1),_c('h5',[_vm._v(_vm._s(_vm.$t("company_information")))]),_c('b-card',[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"5","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"company-name-field-group","label":_vm.$t('company_name') + ':',"label-for":"company-name-field"}},[_c('validation-provider',{attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"HomeIcon"}})],1),_c('b-form-input',{attrs:{"id":"company-name-field","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.company_name_after),callback:function ($$v) {_vm.company_name_after=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"company_name_after"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"4","md":"4","lg":"4"}},[_c('b-form-group',{attrs:{"id":"company-address-group","label":_vm.$t('company-address') + ':',"label-for":"company-address"}},[_c('validation-provider',{attrs:{"name":"Company Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-address","state":errors.length > 0 ? false : null,"required":"","disabled":_vm.disableInputs},model:{value:(_vm.company_address),callback:function ($$v) {_vm.company_address=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"company_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"3","md":"2","lg":"2"}},[_c('b-form-group',{attrs:{"id":"company-address-nr-group","label":_vm.$t('company-address-nr') + ':',"label-for":"company-address-nr"}},[_c('validation-provider',{attrs:{"name":"Company Address Nr","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-address-nr","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.company_address_nr),callback:function ($$v) {_vm.company_address_nr=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"company_address_nr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"company-uid-group","label":_vm.$t('company_uid ') + '(CHE-397.517.219 > CHE397517219):',"label-for":"company-uid"}},[_c('validation-provider',{attrs:{"name":"Company UID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-uid","required":"","state":errors.length > 0 ? false : null},model:{value:(_vm.company_uid),callback:function ($$v) {_vm.company_uid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"company_uid"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"2","md":"2","lg":"2"}},[_c('b-form-group',{attrs:{"id":"company-zip-group","label":_vm.$t('zip') + ':',"label-for":"company-zip"}},[_c('validation-provider',{attrs:{"name":"Company Zip","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-zip","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.company_zip),callback:function ($$v) {_vm.company_zip=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"company_zip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"4","md":"4","lg":"4"}},[_c('b-form-group',{attrs:{"id":"company-city-group","label":_vm.$t('city') + ':',"label-for":"company-city"}},[_c('validation-provider',{attrs:{"name":"Company City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-city","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.company_city),callback:function ($$v) {_vm.company_city=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"company_city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"company-legal-form-group","label":_vm.$t('legal_form') + ':',"label-for":"company-legal-form"}},[_c('validation-provider',{attrs:{"name":"Company Legal Form","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"company-legal-form","options":_vm.legal_forms,"required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.company_legal_form),callback:function ($$v) {_vm.company_legal_form=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"company_legal_form"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1)],1)],1),_c('h5',[_vm._v(_vm._s(_vm.$t("main_contact_person")))]),_c('b-card',[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-gender-group","label":_vm.$t('salutation') + ':',"label-for":"main-contact-gender"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"UserIcon"}})],1),_c('b-form-select',{attrs:{"id":"main-contact-gender","options":[
                    {
                      text: _vm.$t('select_one'),
                      value: null,
                      disabled: true,
                    },
                    { text: _vm.$t('mr'), value: '0' },
                    { text: _vm.$t('mrs'), value: '1' } ],"required":""},model:{value:(_vm.main_contact_gender),callback:function ($$v) {_vm.main_contact_gender=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"main_contact_gender"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-email-group","label":_vm.$t('company_email_address') + ':',"label-for":"main-contact-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"MailIcon"}})],1),_c('b-form-input',{attrs:{"id":"main-contact-email","required":"","state":errors.length > 0 ? false : null},model:{value:(_vm.main_contact_email),callback:function ($$v) {_vm.main_contact_email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"main_contact_email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-first-name-group","label":_vm.$t('first_name') + ':',"label-for":"main-contact-first-name"}},[_c('validation-provider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"main-contact-first-name","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.main_contact_first_name),callback:function ($$v) {_vm.main_contact_first_name=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"main_contact_first_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-tel-group","label":_vm.$t('tel') + ':',"label-for":"main-contact-tel"}},[_c('validation-provider',{ref:"phoneValidator",attrs:{"name":"phoneValidation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{class:_vm.main_contact_tel.length > 0
                        ? 'phoneCountries '
                        : _vm.showBlueBorder
                        ? 'phoneCountries is-empty'
                        : 'phoneCountries',attrs:{"id":"phoneCountries"}},[_c('b-form-select',{attrs:{"options":_vm.mobileCountries},model:{value:(_vm.phoneContries),callback:function ($$v) {_vm.phoneContries=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"phoneContries"}})],1),_c('b-form-input',{staticClass:"form-control",attrs:{"id":"main-contact-tel","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},on:{"input":_vm.checkPhoneFormat},model:{value:(_vm.main_contact_tel),callback:function ($$v) {_vm.main_contact_tel=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"main_contact_tel"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))]),(_vm.phoneValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.phoneValidation)+" ")]):_vm._e()]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-last-name-group","label":_vm.$t('last_name') + ':',"label-for":"main-contact-last-name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"main-contact-last-name","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.main_contact_last_name),callback:function ($$v) {_vm.main_contact_last_name=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"main_contact_last_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-position-group","label":_vm.$t('position_in_the_company') + ':',"label-for":"main-contact-position"}},[_c('validation-provider',{attrs:{"name":"Position in the Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"BriefcaseIcon"}})],1),_c('b-form-input',{attrs:{"id":"main-contact-position","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.main_contact_position),callback:function ($$v) {_vm.main_contact_position=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"main_contact_position"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1)],1)],1)],1),(_vm.invoice && _vm.invoice.recourse < 2)?_c('b-card',{staticClass:"alert alert-secondary"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t('debtor_modal_message'))+" ")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }