var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"MainContact",attrs:{"id":"MainContact","scrollable":"","no-close-on-backdrop":"","hide-header-close":"","size":"lg"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){return [(_vm.resLoader)?_c('img',{attrs:{"width":"50","height":"50","src":"/new-loader.svg"}}):_vm._e(),_c('b-button',{attrs:{"size":"sm","variant":"outline-primary"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("back"))+" ")]),_c('b-button',{attrs:{"disabled":_vm.resLoader,"size":"sm","variant":"primary"},on:{"click":_vm.saveCompanyInfo}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])]}}])},[_c('p',{},[_c('validation-observer',{ref:"companyRegisterValidation",attrs:{"name":"RegisterCompany"}},[_c('b-form',[_c('h5',[_vm._v(_vm._s(_vm.$t("main_contact_person")))]),_c('b-card',[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-gender-group","label":_vm.$t('salutation') + ':',"label-for":"main-contact-gender"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"UserIcon"}})],1),_c('b-form-select',{attrs:{"id":"main-contact-gender","options":[
                        {
                          text: _vm.$t('select_one'),
                          value: null,
                          disabled: true,
                        },
                        { text: _vm.$t('mr'), value: '0' },
                        { text: _vm.$t('mrs'), value: '1' } ],"required":""},model:{value:(_vm.main_contact_gender),callback:function ($$v) {_vm.main_contact_gender=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"main_contact_gender"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-email-group","label":_vm.$t('company_email_address') + ':',"label-for":"main-contact-email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"MailIcon"}})],1),_c('b-form-input',{attrs:{"id":"main-contact-email","required":"","state":errors.length > 0 ? false : null},model:{value:(_vm.main_contact_email),callback:function ($$v) {_vm.main_contact_email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"main_contact_email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-first-name-group","label":_vm.$t('first_name') + ':',"label-for":"main-contact-first-name"}},[_c('validation-provider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"main-contact-first-name","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.main_contact_first_name),callback:function ($$v) {_vm.main_contact_first_name=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"main_contact_first_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-tel-group","label":_vm.$t('tel') + ':',"label-for":"main-contact-tel"}},[_c('validation-provider',{attrs:{"name":"tel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"id":"phoneCountries"}},[_c('b-form-select',{attrs:{"options":_vm.mobileCountries},model:{value:(_vm.phoneContries),callback:function ($$v) {_vm.phoneContries=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"phoneContries"}})],1),_c('b-form-input',{staticClass:"form-control",attrs:{"id":"main-contact-tel","state":errors.length > 0 ? false : null},on:{"input":_vm.checkPhoneFormat},model:{value:(_vm.main_contact_tel),callback:function ($$v) {_vm.main_contact_tel=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"main_contact_tel"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))]),(_vm.phoneValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.phoneValidation))]):_vm._e()]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-last-name-group","label":_vm.$t('last_name') + ':',"label-for":"main-contact-last-name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"main-contact-last-name","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.main_contact_last_name),callback:function ($$v) {_vm.main_contact_last_name=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"main_contact_last_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-position-group","label":_vm.$t('position_in_the_company') + ':',"label-for":"main-contact-position"}},[_c('validation-provider',{attrs:{"name":"Position in the Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"BriefcaseIcon"}})],1),_c('b-form-input',{attrs:{"id":"main-contact-position","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.main_contact_position),callback:function ($$v) {_vm.main_contact_position=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"main_contact_position"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1)],1)],1)],1),(_vm.invoice.recourse < 2)?_c('b-card',{staticClass:"alert alert-secondary"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t('debtor_modal_message'))+" ")])],1)],1):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }